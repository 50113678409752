<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-10 mx-auto">
        <div class="card border-0 shadow-none">
          <div class="card-body p-5">
            <div class="row">
              <div class="col">
                <h3 class="fw-bold mb-5">Amchara Client Portal</h3>
                <div class="alert alert-success">
                  <p>
                    Welcome to the Amchara Retreats client portal, from here you
                    can manage your bookings, treatments, health questionnaires
                    and more.
                  </p>
                </div>
                <router-link to="/login" class="btn btn-success float-end"
                  >Login to Client Portal</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
